<template>
    <v-row no-gutters>
        <v-col cols="12">
            <RealmBar :accountId="$route.params.accountId" :realm="realm" class="mb-6" v-if="realm"></RealmBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Dynamic DNS records</h1>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col style="text-align: center" cols="12" sm="6">
                    <v-textarea :value="tinydnsconfig"></v-textarea>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mb-8">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <template v-if="displayToken">
                        <v-alert type="info" border="top" colored-border>
                            <v-row align="top">
                                <v-col class="grow">
                                    <p>A new API token was created:</p>
                                    <v-textarea v-model="displayToken" ref="displayTokenRef" readonly outlined @focus="selectTokenForClipboard">
                                        <template #append-outer>
                                            <v-btn icon @click="copyTokenToClipboard">
                                                <font-awesome-icon :icon="['far', 'copy']"/>
                                            </v-btn>
                                        </template>
                                    </v-textarea>
                                    <p>Copy it to a secure location before closing this message. We cannot display the token again after this message is closed.</p>
                                    <!-- <v-btn @click="displayToken = null">Close</v-btn> -->
                                </v-col>
                                <v-col class="shrink">
                                    <v-btn icon @click="displayToken = null" color="black">
                                        <font-awesome-icon :icon="['fas', 'times']"/>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-alert>
                    </template>

                    <v-card tile elevation="4" class="pa-0" v-if="clientTokenList">
                        <v-app-bar color="blue darken-2" dark flat dense>
                            <v-app-bar-title>Client tokens ({{ clientTokenList.length }})</v-app-bar-title>
                            <v-spacer/>
                            <v-btn icon @click="createNewItemDialog = true">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                            </v-btn>
                        </v-app-bar>

                        <v-card-text v-if="clientTokenList.length === 0">
                            <p>A client token grants permissions to your application to manage accounts, users, orders, and more via the EtherLink API.</p>
                            <!-- <v-btn style="background-color: blue darken-2; color: #ffffff;" elevation="2" @click="createNewItemDialog = true">
                                Add
                            </v-btn> -->
                        </v-card-text>

                        <v-list dense v-if="clientTokenList.length > 0">
                            <v-list-item v-for="(item, idx) in clientTokenList" :key="idx" @click="openEditItemDialog(item)">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item.label }} <!-- .{{ item.domain }} -->
                                        <v-chip v-if="item.value === null" label small class="px-1 ml-2 blue white--text">New</v-chip>
                                        <v-chip v-if="Array.isArray(item.value) && item.value.length === 1" label small class="px-1 ml-2 grey white--text">{{ ipaddr }}</v-chip>
                                        <template v-if="Array.isArray(item.value) && item.value.length > 1">
                                            <!-- TODO: do we need to limit how many are on the line, and offer a popup or something to see the full list of ipaddr? -->
                                            <v-chip v-for="(ipaddr, idx) in item.value" :key="idx" label small class="px-1 ml-2 grey white--text">{{ ipaddr }}</v-chip>
                                        </template>
                                        <!-- <v-chip v-if="item.status === 'new'" label small class="px-1 ml-2 yellow">{{ item.status }}</v-chip>
                                        <v-chip v-if="item.status === 'pending'" label small class="px-1 ml-2 blue lighten-4">{{ item.status }}</v-chip>
                                        <v-btn icon small color="blue darken-2" @click="openEditItemDialog(item)">
                                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width/>
                                        </v-btn> -->
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                    <v-dialog v-model="createNewItemDialog" max-width="600">
                    <v-card tile elevation="4" class="pa-0" max-width="600">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="blue--text text--darken-2">Add Client Token</v-toolbar-title>
                        </v-toolbar>
                        <!-- <v-card-text class="px-5">
                        </v-card-text> -->
                        <v-form @submit.prevent="createItem" @keyup.enter.native.prevent="createItem" class="px-2">
                            <v-text-field v-model="newItemLabel" label="Label" hint="A label for this client token; for example which application or server is using it" ref="newItemLabelInput"></v-text-field>
                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="blue white--text" @click="createItem" :disabled="!isNewItemFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="createNewItemDialog = false">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                    <v-dialog v-model="editItemDialog" max-width="600">
                    <v-card tile elevation="4" class="pa-0" max-width="600">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="blue--text text--darken-2">API token</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                            <p class="text-overline mb-0">Client</p>
                            <p class="mb-0">{{ editItemLabel }}<!-- .{{ editItemDomain }} --></p>

                            <!-- <p class="text-overline mb-0 mt-4">IP Address</p>
                            <p v-if="!Array.isArray(editItemValue) || editItemValue.length === 0">Use the Dynamic DNS client software to update this record.</p>
                            <v-list v-if="Array.isArray(editItemValue) && editItemValue.length > 1">
                                <v-list-item v-for="(ipaddr, idx) in editItemValue" :key="idx">
                                    <v-list-item-title>{{ ipaddr }}</v-list-item-title>
                                </v-list-item>
                            </v-list> -->

                            <!-- <p class="text-overline mb-0">Client software</p>
                            <p class="mb-0">Link to download client</p>
                            <p class="mb-0">Link to get access token</p> -->

                            <p class="text-overline mb-0 mt-4">Other</p>
                            <p><router-link :to="{ name: 'account-delete-client-token', params: { accountId: this.$route.params.accountId }, query: { id: this.editItemId } }">How to delete this API token</router-link></p>

                            <!-- <v-form @submit.prevent="editItem" @keyup.enter.native.prevent="editItem" class="px-2">
                                <v-select v-model="editItemStatus" label="Status" :items="statusChoices"></v-select>
                            </v-form> -->

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <!-- <v-btn elevation="4" class="blue white--text" @click="editItem" :disabled="!isEditItemFormComplete">
                                <span>Save</span>
                            </v-btn> -->
                            <v-btn text color="grey" @click="editItemDialog = false">
                                <span>Close</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
/* vuetify places the icon too low from the top of the textarea */
.v-input .v-input__append-outer {
    margin-top: 4px !important;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import RealmBar from '@/components/RealmBar.vue';

export default {
    components: {
        AccessDeniedOverlay,
        RealmBar,
    },
    data: () => ({
        realm: null,
        forbiddenError: false,
        account: null,
        clientTokenList: null,

        displayToken: null,

        submitFormTimestamp: null,

        createNewItemDialog: false,
        newItemDomain: null,
        newItemLabel: null,

        editItemDialog: false,
        editItemId: null,
        editItemDomain: null,
        editItemLabel: null,
        editItemValue: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryTextStyle: 'primaryTextStyle',
            primaryIconButtonStyle: 'primaryIconButtonStyle',
        }),
        isNewItemFormComplete() {
            return /* typeof this.newItemDomain === 'string' && this.newItemDomain.length > 0 && */ typeof this.newItemLabel === 'string' && this.newItemLabel.length > 0;
        },
        isEditItemFormComplete() {
            return typeof this.editItemDomain === 'string' && this.editItemDomain.length > 0 && typeof this.editItemLabel === 'string' && this.editItemLabel.length > 0;
        },
    },
    watch: {
        createNewItemDialog(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.newItemLabel = '';
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('newItemLabelInput'); }, 1);
                });
            }
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).self.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadClientTokenList() {
            try {
                this.$store.commit('loading', { loadClientTokenList: true });
                const response = await this.$client.account(this.$route.params.accountId).clientToken.search();
                if (response?.list) {
                    this.clientTokenList = response.list;
                }
            } catch (err) {
                console.error('loadClientTokenList failed', err);
            } finally {
                this.$store.commit('loading', { loadClientTokenList: false });
            }
        },
        async createItem() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            try {
                this.$store.commit('loading', { createItem: true });
                const item = {
                    label: this.newItemLabel,
                };
                const response = await this.$client.account(this.$route.params.accountId).clientToken.create(item);
                if (response?.isCreated && response.id && response.token) {
                    this.createNewItemDialog = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.displayToken = response.token;
                    // this.clientTokenList.push(response.item);
                    this.loadClientTokenList();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('createItem failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { createItem: false });
            }
        },
        async openEditItemDialog(item) {
            this.editItemId = item.id;
            // this.editItemDomain = item.domain;
            this.editItemLabel = item.label;
            // this.editItemValue = item.value;
            this.editItemDialog = true;
        },
        /*
        async editItem() {
            try {
                this.$store.commit('loading', { editItem: true });
                const response = await this.$client.main().dynamicSharedDomain.edit({ name: this.editItemLabel }, { status: this.editItemStatus });
                if (response?.isEdited) {
                    this.createNewItemDialog = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    const idx = this.clientTokenList?.findIndex((item) => item.name === this.editItemLabel);
                    if (Number.isInteger(idx) && idx > -1) {
                        this.clientTokenList.splice(idx, 1, { name: this.editItemLabel, status: this.editItemStatus });
                    } else {
                        this.loadClientTokenList();
                    }
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('editItem failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { editItem: false });
            }
        },
        */
        async selectTokenForClipboard() {
            const input = this.$refs.displayTokenRef.$el.querySelector('textarea');
            input.focus();
            input.select();
            input.setSelectionRange(0, input.value.length);
        },
        async copyTokenToClipboard() {
            let isCopied = false;
            if (navigator?.clipboard) {
                try {
                    await navigator.clipboard.writeText(this.displayToken);
                    isCopied = true;
                } catch (err) {
                    console.error('failed to copy token to clipboard using Clipboard API', err);
                }
            }
            if (!navigator?.clipboard || !isCopied) {
                try {
                    this.selectTokenForClipboard();
                    document.execCommand('copy');
                    isCopied = true;
                } catch (err) {
                    console.error('failed to copy token to clipboard using document.execCommand', err);
                }
            }
            if (isCopied) {
                this.$bus.$emit('snackbar', { type: 'info', message: 'Copied token to clipboard' });
            } else {
                this.$bus.$emit('snackbar', { type: 'warning', headline: 'Cannot copy token to clipboard', message: 'Use mouse or keyboard commands instead' });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadClientTokenList();
    },
};
</script>
